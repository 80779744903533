<template>
  <div
    class="d-flex login align-items-center"
    style="
      border-radius: 4px;
      background-color: #e4ecfe;
      background-image: url('../../assets/bg-pattern.png'),
        url('../assets/spiral-pattern.png');
      background-repeat: repeat, repeat;
      background-position: center, center;
      background-size: cover;
    "
  >
    <div class="container" @submit.prevent="tryToLogIn">
      <div class="row justify-content-center">
        <div class="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-4 mt-3">
          <div class="login-container p-0">
            <div
              class="logo pt-4 pb-4 text-center"
              style="border-top-left-radius: 4px; border-top-right-radius: 4px"
            >
              <img
                style="height: 32px"
                src="../../assets/logo.png"
                alt="Logo"
              />
            </div>
            <div class="px-4 py-2">
              <div class="text-center m-2">
                <h5 class="text-dark mt-3 text-center title text-muted">
                  Sign In
                </h5>
              </div>
              <div
                v-if="isAuthError"
                class="alert alert-danger alert-dismissible fade show fw-normal"
                role="alert"
              >
                {{ authError }}
                <button
                  type="button"
                  class="btn-close btn-sm"
                  @click="isAuthError = false"
                  aria-label="Close"
                ></button>
              </div>

              <div
                v-if="isOtpMessage"
                class="alert alert-info alert-dismissible fade show fw-normal font-12"
                role="alert"
              >
                {{ authError }}
                <button
                  type="button"
                  class="btn-close btn-sm"
                  @click="isOtpMessage = false"
                  aria-label="Close"
                ></button>
              </div>

              <form class="login-form mt-0">
                <div class="form-group fw-bold">
                  <label for="username">Username</label>
                  <input
                    class="form-control"
                    type="text"
                    id="username"
                    v-model="username"
                    :disabled="twoFaEnabled"
                    name="username"
                    placeholder="Enter Username"
                    required
                  />
                </div>

                <div class="form-group input-group-2">
                  <div class="d-flex justify-content-between">
                    <label class="fw-bold">Password</label>
                    <router-link
                      v-if="!twoFaEnabled"
                      to="/forgot-password"
                      class="text-muted fw-normal"
                      style="font-size: 0.8rem"
                    >
                      Forgot your password?
                    </router-link>
                  </div>
                  <div class="input-group">
                    <input
                      v-model="password"
                      class="form-control"
                      :type="hidePassword ? 'password' : 'text'"
                      placeholder="Enter Password"
                      :disabled="twoFaEnabled"
                    />
                    <div
                      class="input-group-text text-white"
                      @click="togglePassword"
                      style="cursor: pointer; background-color: #727cf5"
                    >
                      <i
                        :class="
                          hidePassword ? 'ri-eye-line' : 'ri-eye-off-line'
                        "
                        style="font-size: 18px"
                      ></i>
                    </div>
                  </div>
                </div>

                <div v-if="twoFaEnabled">
                  <div class="form-group">
                    <label class="fw-bold" for="otp">OTP</label>
                    <input
                      id="otp"
                      v-model="otp"
                      type="text"
                      class="form-control"
                      placeholder="Enter OTP"
                    />
                  </div>
                  <div class="form-group mb-0 text-end">
                    <a
                      class="btn-sm cursor-pointer"
                      style="
                        text-decoration: underline;
                        cursor: pointer;
                        font-weight: bold;
                        font-size: 14px;
                      "
                      @click="resendOtp"
                    >
                      Resend OTP
                    </a>
                  </div>
                </div>

                <div class="text-center mb-3">
                  <button type="submit" class="btn btn-primary buttonPrimary">
                    Log In
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div>
            <div>
              <p
                class="text-center text-muted mt-3 mb-3 fw-bold"
                style="font-size: 14px"
              >
                v2.0.0
              </p>
            </div>

            <div class="loginFooter text-muted text-center fw-normal">
              ©{{ new Date().getFullYear() }} - All rights reserved by ProenX
              Pte Ltd.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from "vue";
import { useRouter } from "vue-router";
import { useAuthStore } from "../../store/auth";

const router = useRouter();
const authStore = useAuthStore();

// State variables
const username = ref("");
const password = ref("");
const otp = ref(null);
const transactionId = ref(null);
const twoFaEnabled = ref(false);
const authError = ref(null);
const tryingToLogIn = ref(false);
const isAuthError = ref(false);
const isOtpMessage = ref(false);
const hidePassword = ref(true);

// Lifecycle hooks
onMounted(() => {
  document.title = "Login | RDEP";
  document.addEventListener("keydown", handleKeyDown);
});

onUnmounted(() => {
  document.removeEventListener("keydown", handleKeyDown);
});

// Toggle password visibility
const togglePassword = () => {
  hidePassword.value = !hidePassword.value;
};

// Handle "Enter" key press
const handleKeyDown = (event) => {
  if (event.key === "Enter") {
    tryToLogIn();
  }
};

// Attempt login
const tryToLogIn = () => {
  tryingToLogIn.value = false;
  authError.value = null;
  return authStore
    .LogIn({
      username: username.value,
      password: password.value,
      transactionId: transactionId.value,
      otp: otp.value,
    })
    .then((response) => {
      if (response.statusCode === "400" && response.twoFaEnabled) {
        twoFaEnabled.value = true;
        transactionId.value = response.transactionId;
        tryingToLogIn.value = true;
        authError.value = response.statusMessage;
        isOtpMessage.value = true;
        isAuthError.value = false;
        return false;
      }
      tryingToLogIn.value = true;
      // isAuthError.value = true;
      if (response.firstTimeLogin) {
        router.push("/reset-password");
      } else {
        if (response.landingPage === "rdep-receipt") {
          router.push("/rdep-receipt/dashboard");
        } else {
          router.push(response.landingPage);
        }
      }
    })
    .catch((error) => {
      tryingToLogIn.value = false;
      authError.value = error.message;
      isAuthError.value = true;
      isOtpMessage.value = false;
    });
};

// Resend OTP
const resendOtp = async () => {
  tryingToLogIn.value = false;
  authError.value = null;

  try {
    const response = await authStore.LogIn({
      username: username.value,
      password: password.value,
      transactionId: null,
      otp: null,
    });

    if (response.statusCode === "400" && response.twoFaEnabled) {
      twoFaEnabled.value = true;
      transactionId.value = response.transactionId;
      authError.value = response.statusMessage;
      isOtpMessage.value = true;
      isAuthError.value = false;
      otp.value = null;
    } else {
      if (response.firstTimeLogin) {
        router.push("/reset-password");
      } else {
        const landingPage =
          response.landingPage === "rdep-receipt"
            ? "/rdep-receipt/dashboard"
            : response.landingPage;
        router.push(landingPage);
      }
    }
  } catch (error) {
    authError.value = error.message;
    isAuthError.value = true;
    isOtpMessage.value = false;
  } finally {
    tryingToLogIn.value = true;
  }
};
</script>

<style>
.login {
  min-height: 100vh;
  position: relative;
}
.buttonPrimary {
  font-size: 14px;
  padding: 7px 15px;
  border-radius: 3px;
  box-shadow: 0px 2px 6px 0px rgba(114, 124, 245, 0.5) !important;
  background-color: #6366f1 !important;
  border: #6366f1 !important;
}
.logo {
  background-color: #727cf5;
}

.login-container {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.login-container h2 {
  color: #333;
}

.login-form {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 10px;
  margin-top: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin: 4px 4px;
}

.form-group label {
  font-size: 12px;
  margin-bottom: 5px;
  color: #6c757d;
}

.form-group input {
  color: #6c757d !important;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 0.9rem;
}

.login-btn {
  background-color: #727cf5;
  color: #fff;
  padding: 6px 10px;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  font-size: 14px;
}

h6 {
  color: #6c757d;
  font-weight: 700;
}

.forget-password {
  font-size: 12px;
  color: #6c757d;
  text-decoration: none;
  text-align: right;
}

.form-control:focus {
  box-shadow: none;
  border-color: #ced4da; /* Set border color to desired color */
}

.loginFooter {
  margin-top: 30px;

  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 14px !important;
  color: #6c757d; /* Consistent with other muted text */
}
@media (min-height: 598px), (min-width: 100px) {
  .loginFooter {
    position: absolute;
    bottom: 5px;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 12px !important;
    color: #6c757d; /* Consistent with other muted text */
  }
}
</style>
